import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormControl, Spinner, Button, Container, Row, Col } from 'react-bootstrap';
import '../App.css';

async function companySearchAction(e, props, proposedName, setLoading, dispatch) {
  e.preventDefault();
  document.getSelection().removeAllRanges(); // Needed for Chrome

  if (proposedName !== '') {
    dispatch({ type: 'COMPANY_NAME_SEARCH', proposedName});
    setLoading(true);
    const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/name-search?name=${proposedName}`);
    const proposedNameQueryResult = await result.json();
    dispatch({ type: 'COMPANY_NAME_SEARCH_RESULT', proposedNameQueryResult });
    setLoading(false);

    props.history.push(`/search#${proposedName}`);
  } else {
    alert('Name cannot be empty');
  }
}

function Home(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [proposedName, setProposedName] = useState('');

    return (
      <div>
        <div className="Hero-background">
          <Form onSubmit={(e) => { companySearchAction(e, props, proposedName, setLoading, dispatch) } }>
            <Container fluid={true}>
              <Row>
                <Col lg={6} md={6} sm={4}></Col>
                <Col lg={6} md={6} sm={8} className="Hero-title"><h1>Let's Start a Company</h1></Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={4}></Col>
                <Col lg={6} md={6} sm={8} className="Hero-subtitle"><h5>Every great company starts with a great name</h5></Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={4}></Col>
                <Col lg={5} md={6} sm={8}>
                  <p>
                    <FormControl
                      type="text"
                      placeholder="Find a company name to get started..."
                      className="mr-sm-1"
                      value={proposedName}
                      onChange={(e) => setProposedName(e.target.value)}
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={4} xs={0}></Col>
                <Col lg={3} md={6} sm={8} xs={12} className="Hero-action">
                  <p>
                    <Button
                      type="submit"
                      variant={loading ? "outline-success disabled" : "outline-success"}
                      disabled={loading ? true : false}
                    >
                      {loading
                        ? <span><Spinner animation="border" variant="success" size="sm" /> Checking Availability</span>
                        : <span>Check Availability</span>
                      }
                    </Button>
                  </p>
                </Col>
              </Row>
            </Container>
          </Form>

        </div>
      </div>
    );
}

export default Home;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spinner, Button, Container, Card, Row, Col, Badge } from 'react-bootstrap';
import { FaArrowRight, FaCheck, FaTimes } from "react-icons/fa";
import Select, { components } from 'react-select'
import countryList from 'react-select-country-list';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import FormSteps from '../FormSteps';


/*
 *  Required to disable Safari autofill
 */
const Input = ({ children, ...props }) => (
  <components.Input {...props} name="search" />
);

export const ABNRegistrationDetails = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // XXX: this is really a bug not a fix...
    localStorage.removeItem('businessNameApplicationId');
  });

  // ABN Entitlement
  const [entitlementActivityLocation, setEntitlementActivityLocation] = useState('Australia');
  const [entitlementReason, setEntitlementReason] = useState('startingBusiness');

  // Personal details
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [birthDateDay, setBirthDateDay] = useState('');
  const [birthDateMonth, setBirthDateMonth] = useState(0);
  const [birthDateYear, setBirthDateYear] = useState('');

  const [taxFileNumber, setTaxFileNumber] = useState('');

  const [australianTaxResident, setAustralianTaxResident] = useState(false);

  // Optional business name
  const [businessName, setBusinessName] = useState('');
  const [businessNameSearchAttempted, setBusinessNameSearchAttempted] = useState(false);
  const [businessNameLoading, setBusinessNameLoading] = useState(false);
  const [businessNameAvailable, setBusinessNameAvailable] = useState(false);

  const [registrationPeriod, setRegistrationPeriod] = useState(0);

  const [birthState, setBirthState] = useState('NSW');
  const [birthCity, setBirthCity] = useState('');
  const [birthCountry, setBirthCountry] = useState({ "value": "AU", "label": "Australia" });

  // Business location
  const [autocompletedAddress, setAutocompletedAddress] = useState(null);
  const [geocodedAddress, setGeocodedAddress] = useState(null);

  const [businessActivitiesMatchesHomeAddress, setBusinessActivitiesMatchesHomeAddress] = useState(true);
  const [serviceAddressMatchesHomeAddress, setServiceAddressMatchesHomeAddress] = useState(true);

  // Business activity details
  const now = new Date();
  const [businessActiveDateDay, setBusinessActiveDateDay] = useState(now.getDate().toString());
  const [businessActiveDateMonth, setBusinessActiveDateMonth] = useState(now.getMonth());
  const [businessActiveDateYear, setBusinessActiveDateYear] = useState(now.getFullYear().toString());
  const [mainBusinessActivity, setMainBusinessActivity] = useState('');

  // Declarations and Terms
  const [notDisqualified, setNotDisqualified] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const formInvalid = entitlementActivityLocation !== 'Australia'
    || !australianTaxResident
    || !taxFileNumber
    || (registrationPeriod !== 0 && !businessNameAvailable)
    || !businessActivitiesMatchesHomeAddress
    || !serviceAddressMatchesHomeAddress
    || !notDisqualified
    || !termsAccepted;

  const handleAutocompletedAddressChange = async (address) => {
    setAutocompletedAddress(address);
    if (address) {
      const result = await geocodeByPlaceId(address.value.place_id);
      setGeocodedAddress(result);
    }
  };

  const searchName = async () => {
    setBusinessNameSearchAttempted(true);
    setBusinessNameLoading(true);

    // Record this as a conversion in Google Ads
    window.gtag('event', 'conversion', { 'send_to': 'AW-312837668/pXbCCKCz3JsDEKSMlpUB' }); // 'Search Name' conversion action

    const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/name-search?name=${businessName}`);
    const resultJSON = await result.json();

    if (resultJSON.result.code !== 'Unavailable') {
      localStorage.setItem('nameSearchResultId', resultJSON.nameSearchResultId);
      setBusinessNameAvailable(true);
      setRegistrationPeriod(1);
    } else {
      setBusinessNameAvailable(false);
    }

    setBusinessNameLoading(false);
  };

  const submitForm = async () => {
    setSubmissionAttempted(true);

    if (!formInvalid) {

      setLoading(true);

      const options = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entitlement_reason: entitlementReason,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          birth_date: `${birthDateYear}-${parseInt(birthDateMonth) + 1}-${birthDateDay}`,
          tax_file_number: taxFileNumber,
          home_address: {
            autocomplete_result: autocompletedAddress,
            geocoded_result: geocodedAddress,
          },
          business_active_date: `${businessActiveDateYear}-${parseInt(businessActiveDateMonth) + 1}-${businessActiveDateDay}`,
          main_business_activity: mainBusinessActivity,
        }),
      };
      const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/abn-application`, options);
      const resultJSON = await result.json();

      if (resultJSON.status === 'ok') {
        const abnApplicationId = resultJSON.application_id;
        localStorage.setItem('abnApplicationId', abnApplicationId);

        localStorage.setItem('registrationPeriod', registrationPeriod);

        if (registrationPeriod !== 0) {
          const options = {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              term: registrationPeriod,
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              birth_date: `${birthDateYear}-${parseInt(birthDateMonth) + 1}-${birthDateDay}`,
              birth_country: birthCountry,
              birth_state: birthState,
              birth_city: birthCity,
              home_address: {
                autocomplete_result: autocompletedAddress,
                geocoded_result: geocodedAddress,
              },
              abn_application_id: abnApplicationId,
              name_search_result_id: localStorage.getItem('nameSearchResultId'),
            }),
          };
          const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/business-name/application`, options);
          const resultJSON = await result.json();

          if (resultJSON.status === 'ok') {
            localStorage.setItem('businessNameApplicationId', resultJSON.application_id);
            //history.push('/lodge/business-name-registration/review'); // XXX: fix this
          } else {
            setError(true);
          }

        }

        history.push('/lodge/abn-registration/review');
      } else {
        setError(true);
      }

      setLoading(false);
    }
  };

  return (<div>
    <Container>
      <br />
      <FormSteps step={2} />

      <br />


      <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>

        <Card className="text-left">
          <Card.Header>Step 2. ABN Registration Details</Card.Header>
          <Card.Body>

            <h3>ABN Entitlement</h3>

            <br />
            <br />

            <fieldset>

              <Form.Group as={Row}>
                <Form.Label as={Col} column sm={4} className="text-left">
                  Where will your business activities be carried out
                </Form.Label>
                <Col sm={6}>
                  <Form.Check
                    type="radio"
                    label="Australia"
                    checked={entitlementActivityLocation === 'Australia'}
                    onChange={() => setEntitlementActivityLocation('Australia')}
                    name="entitlementActivityLocation"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    type="radio"
                    label="Overseas"
                    isInvalid={entitlementActivityLocation === 'Overseas'}
                    feedback={entitlementActivityLocation === 'Overseas' ? 'Currently only Australian businesses are eligble' : ''}
                    checked={entitlementActivityLocation === 'Overseas'}
                    onChange={() => setEntitlementActivityLocation('Overseas')}
                    name="entitlementActivityLocation"
                    id="formHorizontalRadios2"
                  />


                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="entitlementReason">
                <Form.Label as="legend" column sm={4} className="text-left">
                  Why do you need an ABN
                </Form.Label>
                <Col sm={6}>

                  <Form.Control
                    as="select"
                    onChange={(e) => setEntitlementReason(e.target.value)}
                    value={entitlementReason}
                  >
                    <option value="startingBusiness">Starting or running a business</option>
                    <option value="rentingResidentialProperty">Renting or leasing out a residential property on a regular or continuous basis</option>
                    <option value="interestInProperty">A licence, lease or other grant of an interest in property on a regular or continuous basis</option>
                    <option value="oneOffNonCommercialTransactions">One-off commercial transactions not done in the course of a business for a profit or gain</option>
                    <option value="labourerApprenticeTradeAssistant">Labourer, Apprentice or Trade Assistant</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </fieldset>

            <br />

            <hr />
            <h3>Personal Details</h3>

            <br />
            <br />


            <fieldset>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Full name
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    isValid={firstName !== ''}
                    isInvalid={submissionAttempted && firstName === ''}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    isValid={lastName !== ''}
                    isInvalid={submissionAttempted && lastName === ''}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Email
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    placeholder="you@yourbusiness.com.au"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isValid={email !== ''}
                    isInvalid={submissionAttempted && email === ''}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Phone number
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    placeholder="04xx xxx xxx"
                    value={phone}
                    isValid={phone !== ''}
                    onChange={(e) => setPhone(e.target.value)}
                    isInvalid={submissionAttempted && phone === ''}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Date of birth
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="text"
                    placeholder="Day"
                    value={birthDateDay}
                    onChange={(e) => setBirthDateDay(e.target.value)}
                    isInvalid={submissionAttempted && birthDateDay === ''}
                  />

                </Col>
                <Col sm={2}>

                  <Form.Control
                    as="select"
                    onChange={(e) => setBirthDateMonth(e.target.value)}
                    value={birthDateMonth}
                  >
                    <option value="0">January</option>
                    <option value="1">February</option>
                    <option value="2">March</option>
                    <option value="3">April</option>
                    <option value="4">May</option>
                    <option value="5">June</option>
                    <option value="6">July</option>
                    <option value="7">August</option>
                    <option value="8">September</option>
                    <option value="9">October</option>
                    <option value="10">November</option>
                    <option value="11">December</option>

                  </Form.Control>
                </Col>
                <Col sm={2}>
                  <Form.Control
                    type="text"
                    placeholder="Year"
                    value={birthDateYear}
                    onChange={(e) => setBirthDateYear(e.target.value)}
                    isInvalid={submissionAttempted && birthDateYear === ''}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Tax File Number (TFN)
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    placeholder="TFN"
                    value={taxFileNumber}
                    onChange={(e) => setTaxFileNumber(e.target.value)}
                    isValid={taxFileNumber !== ''}
                    isInvalid={submissionAttempted && taxFileNumber === ''}
                  />
                  <Form.Control.Feedback type="invalid">You must provide your Tax File Number to proceed</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="australianTaxResident">
                <Form.Label column sm={4} className="text-left">
                  Declaration
                </Form.Label>
                <Form.Check>
                  <Form.Check.Input
                    checked={australianTaxResident}
                    onChange={() => setAustralianTaxResident(!australianTaxResident)}
                    isInvalid={submissionAttempted && !australianTaxResident}
                    name="australianTaxResident"
                    id="australianTaxResident"
                  />
                  <Form.Check.Label>I am an Australian resident for tax purposes</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You must be an Australian resident for tax purposes to use this form</Form.Control.Feedback>
                </Form.Check>
              </Form.Group>



            </fieldset>


            <hr />
            <h3>Business Name</h3>

            <br />
            <br />


            <fieldset>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Business Name Search
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    placeholder="Type to search"
                    onChange={e => setBusinessName(e.target.value)}
                  />
                  <br />
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={_ => searchName()}
                    disabled={businessNameLoading}
                  >
                    {businessNameLoading
                      ? <Spinner animation="border" variant="light" size="sm" />
                      : null
                    } Search
                  </Button>
                  <br />
                  <br />
                  <p className="text-muted">
                    If you conduct business under a name other
                    than {firstName && lastName ? <><strong>{firstName} {lastName}</strong></> : 'your name'} it is a
                    requirement to register a business name.
                  </p>
                </Col>
                <Col sm={2}>
                  {businessNameSearchAttempted && !businessNameLoading
                    ? businessNameAvailable
                      ? <h3><Badge variant="success">Available <FaCheck /></Badge></h3>
                      : <h3><Badge variant="danger">Unavailable <FaTimes /></Badge></h3>
                    : null
                  }
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="businessNameRenewal">
                <Form.Label column sm={4} className="text-left">
                  Registration Period
                </Form.Label>
                <Col sm={6}>
                  <Form.Check
                    type="radio"
                    label="Don't register a business name"
                    checked={registrationPeriod === 0}
                    onChange={() => setRegistrationPeriod(0)}
                    name="businessRenewal"
                    id="businessRenewal-1"
                  />
                  <Form.Check
                    type="radio"
                    label="1 Year Registration - $65.00"
                    checked={registrationPeriod === 1}
                    onChange={() => setRegistrationPeriod(1)}
                    name="businessRenewal"
                    id="businessRenewal-2"
                  />
                  <Form.Check
                    type="radio"
                    label="3 Years Registration - $180.00"
                    checked={registrationPeriod === 3}
                    onChange={() => setRegistrationPeriod(3)}
                    name="businessRenewal"
                    id="businessRenewal-3"
                  />
                </Col>
              </Form.Group>

              {registrationPeriod !== 0
                ? <>
                  <Form.Group as={Row} controlId="countryOfBirth">
                    <Form.Label column sm={4} className="text-left">
                      Country of Birth
                    </Form.Label>
                    <Col sm={6}>
                      <Select options={countryList().getData()} value={birthCountry} onChange={value => setBirthCountry(value)} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="birthState">
                    <Form.Label column sm={4} className="text-left">
                      State of Birth
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        as="select"
                        onChange={(e) => setBirthState(e.target.value)}
                        value={birthState}
                      >
                        <option value="NSW">New South Wales</option>
                        <option value="ACT">Australian Capital Territory</option>
                        <option value="NT">Northern Territory</option>
                        <option value="QLD">Queensland</option>
                        <option value="SA">South Australia</option>
                        <option value="TAS">Tasmania</option>
                        <option value="VIC">Victoria</option>
                        <option value="WA">Western Australia</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="birthCity">
                    <Form.Label column sm={4} className="text-left">
                      City/Town of Birth
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="City/Town of Birth"
                        value={birthCity}
                        isValid={birthCity !== ''}
                        onChange={(e) => setBirthCity(e.target.value)}
                        isInvalid={submissionAttempted && birthCity === ''}
                      />
                    </Col>
                  </Form.Group>
                </>
                : undefined
              }


            </fieldset>

            <hr />
            <h3>Business Location</h3>


            <br />
            <br />


            <fieldset>
              <Form.Group as={Row} controlId="homeAddress">
                <Form.Label column sm={4} className="text-left">
                  Home address
                </Form.Label>
                <Col sm={6}>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['au'],
                      },
                      types: ['address'],
                    }}
                    selectProps={{
                      components: { Input },
                      isClearable: true,
                      value: autocompletedAddress,
                      onChange: handleAutocompletedAddressChange,
                      placeholder: 'Start typing address',

                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label as={Col} column sm={4} className="text-left">
                  Is your home address where you will you mainly conduct business?
                </Form.Label>
                <Col sm={6}>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    checked={businessActivitiesMatchesHomeAddress}
                    onChange={() => setBusinessActivitiesMatchesHomeAddress(true)}
                    name="businessActivitiesMatchesHomeAddress"
                    id="businessActivitiesMatchesHomeAddress-yes"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    checked={!businessActivitiesMatchesHomeAddress}
                    onChange={() => setBusinessActivitiesMatchesHomeAddress(false)}
                    feedback={!businessActivitiesMatchesHomeAddress ? 'To select a different business address please contact support on Messenger' : ''}
                    isInvalid={!businessActivitiesMatchesHomeAddress}
                    name="businessActivitiesMatchesHomeAddress"
                    id="businessActivitiesMatchesHomeAddress-no"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label as={Col} column sm={4} className="text-left">
                  Is your home address your address for service of documents?
                </Form.Label>
                <Col sm={6}>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    checked={serviceAddressMatchesHomeAddress}
                    onChange={() => setServiceAddressMatchesHomeAddress(true)}
                    name="serviceAddressMatchesHomeAddress"
                    id="serviceAddressMatchesHomeAddress-yes"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    checked={!serviceAddressMatchesHomeAddress}
                    onChange={() => setServiceAddressMatchesHomeAddress(false)}
                    feedback={!serviceAddressMatchesHomeAddress ? 'To select a different service address please contact support on Messenger' : ''}
                    isInvalid={!serviceAddressMatchesHomeAddress}
                    name="serviceAddressMatchesHomeAddress"
                    id="serviceAddressMatchesHomeAddress-no"
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <hr />
            <h3>Business Activity Details</h3>
            <br />
            <br />

            <fieldset>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Business Active Date
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="text"
                    placeholder="Day"
                    value={businessActiveDateDay}
                    onChange={(e) => setBusinessActiveDateDay(e.target.value)}
                  />
                </Col>
                <Col sm={2}>

                  <Form.Control
                    as="select"
                    onChange={(e) => setBusinessActiveDateMonth(e.target.value)}
                    value={businessActiveDateMonth}
                  >
                    <option value="0">January</option>
                    <option value="1">February</option>
                    <option value="2">March</option>
                    <option value="3">April</option>
                    <option value="4">May</option>
                    <option value="5">June</option>
                    <option value="6">July</option>
                    <option value="7">August</option>
                    <option value="8">September</option>
                    <option value="9">October</option>
                    <option value="10">November</option>
                    <option value="11">December</option>

                  </Form.Control>
                </Col>
                <Col sm={2}>
                  <Form.Control
                    type="text"
                    placeholder="Year"
                    value={businessActiveDateYear}
                    onChange={(e) => setBusinessActiveDateYear(e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Main Business Activity
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    placeholder="Describe your business"
                    value={mainBusinessActivity}
                    onChange={(e) => setMainBusinessActivity(e.target.value)}
                    isValid={mainBusinessActivity !== ''}
                    isInvalid={submissionAttempted && mainBusinessActivity === ''}
                  />
                </Col>
              </Form.Group>


            </fieldset>


            <hr />
            <h3>Declarations and Terms Acceptance</h3>


            <br />
            <br />

            <fieldset>
              <Form.Group as={Row} controlId="notDisqualified">
                <Form.Label column sm={4} className="text-left">
                  Declaration
                </Form.Label>
                <Form.Check>
                  <Form.Check.Input
                    checked={notDisqualified}
                    onChange={() => setNotDisqualified(!notDisqualified)}
                    isInvalid={submissionAttempted && !notDisqualified}
                    name="notDisqualified"
                    id="notDisqualified"
                  />
                  <Form.Check.Label>I am not disqualified or otherwise ineligible to apply for an ABN</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You must not be disqualified or otherwise ineligible to apply for an ABN to use this form</Form.Control.Feedback>
                </Form.Check>
              </Form.Group>

              <Form.Group as={Row} controlId="termsAccepted">
                <Form.Label column sm={4} className="text-left">
                  Terms of Service<br /><a href="/terms" className="text-muted">See Terms of Service</a>
                </Form.Label>
                <Form.Check>
                  <Form.Check.Input
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                    isInvalid={submissionAttempted && !termsAccepted}
                    name="termsAccepted"
                    id="termsAccepted"
                  />
                  <Form.Check.Label>I accept the Terms of Service</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You must accept the terms of service to use this form</Form.Control.Feedback>
                </Form.Check>
              </Form.Group>

            </fieldset>
          </Card.Body>
          <Card.Footer className="text-right">
            {error ? 'An error ocurred, please contact support via Messenger ' : ''}
            {submissionAttempted && formInvalid ? ' Please correct errors above ' : ''}
            <Button
              type="submit"
              variant="success"
              disabled={loading}
            >
              {loading
                ? <Spinner animation="border" variant="light" size="sm" />
                : null
              } Continue <FaArrowRight />
            </Button>
          </Card.Footer>
        </Card>

      </Form>

      <br /><br /><br /><br />


    </Container>
  </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col, Table } from 'react-bootstrap';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import FormSteps from './FormSteps';
import CheckoutForm from "./CheckoutForm";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const Lodge = (props) => {

    const businessNameApplicationId = localStorage.getItem('businessNameApplicationId');
    const abnApplicationId = localStorage.getItem('abnApplicationId');

    const [stripeClientSecret, setStripeClientSecret] = useState(undefined);

    const [invoice, setInvoice] = useState([]);
    const [total, setTotal] = useState('');

    useEffect(() => {
        // Record conversion in Google Ads
        window.gtag('event', 'conversion', { 'send_to': 'AW-312837668/OexRCPGxzaADEKSMlpUB' }); // Begin checkout conversion action

        window.scrollTo(0, 0);

        const fetchPaymentToken = async () => {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "business_name_application_id": businessNameApplicationId,
                    "abn_application_id": abnApplicationId,
                }),
            };
            const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/pay`, options);
            const resultJSON = await result.json();

            setStripeClientSecret(resultJSON.clientSecret);
            setInvoice(resultJSON.invoice);
            setTotal((resultJSON.total / 100).toFixed(2));
        };
        fetchPaymentToken();
    }, [setStripeClientSecret]);

    const options = {
        clientSecret: stripeClientSecret,
    }

    return (
        <div>
            <Container>
                <div className="mt-4 mt-5">
                    <FormSteps step={4} />
                    <Card className="text-left mt-4 mb-5">
                        <Card.Header>Step 4. Pay &amp; Lodge</Card.Header>
                        <Card.Body>
                            <h3>Summary</h3>
                            <Row className="justify-content-md-center mt-5">
                                <Col sm={8}>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Yearly Price</th>
                                                <th>Years</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoice.map((item) => <tr>
                                                <td>{item.description}</td>
                                                <td>{isNaN(item.yearlyPrice) ? item.yearlyPrice : (item.yearlyPrice / 100).toFixed(2)}</td>
                                                <td>{item.years}</td>
                                                <td className="text-right">${(item.total / 100).toFixed(2)}</td>
                                            </tr>)
                                            }
                                            <tr>
                                                <td colSpan={2}></td>
                                                <td className="text-right"><strong>Total</strong></td>
                                                <td className="text-right"><strong>${total}</strong></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <h4 className="text-center mt-3">Your Registration Certificates will be <br /> emailed to you after payment and lodgement</h4>
                            <Row className="justify-content-md-center mt-5">
                                <Col sm={8}>
                                    {stripeClientSecret
                                        ? <Elements stripe={stripePromise} options={options}>
                                            <CheckoutForm />
                                        </Elements>
                                        : undefined
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </div>
    );
}
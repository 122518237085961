export const searchReducer = (state = {}, action) => {
  switch(action.type) {
    case 'COMPANY_NAME_SEARCH':
      return {
        ...state,
        nameSearch: {
          ...state.nameSearch,
          proposedName: action.proposedName
        }
      }
    case 'COMPANY_NAME_SEARCH_RESULT':
      return {
        ...state,
        nameSearch: {
          ...state.nameSearch,
          proposedNameQueryResult: action.proposedNameQueryResult
        }
      }
    default:
      return state;
  }
}

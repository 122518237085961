import React from 'react';
import { Card, Nav, ProgressBar } from 'react-bootstrap';

function FormSteps({ companyName, step }) {
    const totalSteps = 4;
    const currentPergentage = step / totalSteps * 100;
    return (
        <Card className="text-left">
            <Card.Header>
                <h2 className="text-left" style={{ textTransform: 'capitalize' }}>{companyName ? companyName : 'New ABN Application'}</h2>
                <h5>Australian Business Number Registration Form</h5>
            </Card.Header>
            <Card.Body>
                <Nav fill variant="pills">
                    <Nav.Item>
                        <Nav.Link href="/structure-selection" disabled={step <= 1}>Step 1. Business Structure</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/lodge/abn-registration" eventKey="disabled" disabled={step <= 2}>Step 2. Registration Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/lodge/abn-registration/review" eventKey="disabled" disabled={step <= 3}>Step 3. Review & Confirm</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/lodge/abn-registration/lodge" eventKey="disabled" disabled={step <= 4}>Step 4. Pay & Lodge</Nav.Link>
                    </Nav.Item>
                </Nav>
                <ProgressBar now={currentPergentage} label={`${currentPergentage}%`} />
            </Card.Body>
        </Card>
    );
}

export default FormSteps;
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Form, Button, Container, Card, Row, Col } from 'react-bootstrap';
import { FaArrowRight } from "react-icons/fa";

import FormSteps from '../FormSteps';


export const ABNRegistrationReview = (props) => {
  const history = useHistory();
  const proposedName = localStorage.getItem('proposedName');
  const applicationId = localStorage.getItem('abnApplicationId');

  const [abnApplication, setABNApplication] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchABNApplication = async () => {

      const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/abn-application/${applicationId}`);
      const resultJSON = await result.json();

      setABNApplication(resultJSON.application);
    };
    fetchABNApplication();
  }, [setABNApplication]);

  const submitForm = async () => {

    history.push('/lodge');

  };

  const eligibilityResonMap = {
    'startingBusiness': 'Starting or running a business',
    'rentingResidentialProperty': 'renting or leasing out a residential property on a regular or continuous basis',
    'interestInProperty': 'A licence, lease or other grant of an interest in property on a regular or continuous basis',
    'oneOffNonCommercialTransactions': 'One-off commercial transactions not done in the course of a business for a profit or gain',
    'labourerApprenticeTradeAssistant': 'Labourer, Apprentice or Trade Assistant',
  };

  return (<div>
    <Container>
      <br />
      <FormSteps companyName={proposedName} step={3} />

      <br />


      <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>

        <Card className="text-left">
          <Card.Header>Step 3. Review ABN Registration Details</Card.Header>
          <Card.Body>

            <h3>ABN Entitlement</h3>

            <br />
            <br />

            <fieldset>

              <Form.Group as={Row}>
                <Form.Label as={Col} column sm={4} className="text-left">
                  Where will your business activities be carried out
                </Form.Label>
                <Col sm={6}>
                  Australia

                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="entitlementReason">
                <Form.Label as="legend" column sm={4} className="text-left">
                  Why do you need an ABN
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? eligibilityResonMap[abnApplication.entitlement_reason] : ''}
                </Col>
              </Form.Group>
            </fieldset>

            <br />

            <hr />
            <h3>Personal Details</h3>

            <br />
            <br />


            <fieldset>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Full name
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? `${abnApplication.first_name} ${abnApplication.last_name}` : ''}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Email
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? abnApplication.email : ''}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Phone number
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? abnApplication.phone : ''}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Date of birth
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? DateTime.fromISO(abnApplication.birth_date).toLocaleString() : ''}
                </Col>

              </Form.Group>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Tax File Number (TFN)
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? abnApplication.tax_file_number : ''}
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Declaration
                </Form.Label>
                <Form.Check
                  label="I am an Australian resident for tax purposes"
                  checked={true}
                  disabled={true}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                />
              </Form.Group>

            </fieldset>


            <hr />
            <h3>Business Location</h3>


            <br />
            <br />


            <fieldset>
              <Form.Group as={Row} controlId="homeAddress">
                <Form.Label column sm={4} className="text-left">
                  Home address
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? abnApplication.home_address_text : ''}
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label as={Col} column sm={4} className="text-left">
                  Is your home address where you will you mainly conduct business?
                </Form.Label>
                <Col sm={6}>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    checked={true}
                    disabled={true}
                    name="businessActivitiesMatchesHomeAddress"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    checked={false}
                    disabled={true}
                    name="businessActivitiesMatchesHomeAddress"
                    id="formHorizontalRadios2"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label as={Col} column sm={4} className="text-left">
                  Is your home address your address for service of documents?
                </Form.Label>
                <Col sm={6}>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    checked={true}
                    disabled={true}
                    name="serviceAddressMatchesHomeAddress"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    checked={false}
                    disabled={true}
                    name="serviceAddressMatchesHomeAddress"
                    id="formHorizontalRadios2"
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <hr />
            <h3>Business Activity Details</h3>
            <br />
            <br />

            <fieldset>
              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Business Active Date
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? DateTime.fromISO(abnApplication.business_active_date).toLocaleString() : ''}
                </Col>

              </Form.Group>

              <Form.Group as={Row} controlId="registeredOfficeOccupier">
                <Form.Label column sm={4} className="text-left">
                  Main Business Activity
                </Form.Label>
                <Col sm={6}>
                  {abnApplication ? abnApplication.main_business_activity : ''}
                </Col>
              </Form.Group>


            </fieldset>


            <hr />
            <h3>Declarations and Terms Acceptance</h3>


            <br />
            <br />

            <fieldset>
              <Form.Group as={Row} controlId="notDisqualified">
                <Form.Label column sm={4} className="text-left">
                  Declaration
                </Form.Label>
                <Form.Check>
                  <Form.Check.Input
                    checked={true}
                    disabled={true}
                    name="notDisqualified"
                    id="notDisqualified"
                  />
                  <Form.Check.Label>I am not disqualified or otherwise ineligible to apply for an ABN</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You must not be disqualified or otherwise ineligible to apply for an ABN to use this form</Form.Control.Feedback>
                </Form.Check>
              </Form.Group>

              <Form.Group as={Row} controlId="termsAccepted">
                <Form.Label column sm={4} className="text-left">
                  Terms of Service<br /><a href="/terms" className="text-muted">See Terms of Service</a>
                </Form.Label>
                <Form.Check>
                  <Form.Check.Input
                    checked={true}
                    disabled={true}
                    name="termsAccepted"
                    id="termsAccepted"
                  />
                  <Form.Check.Label>I accept the Terms of Service</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You must accept the terms of service to use this form</Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
            </fieldset>

          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              type="submit"
              variant="success"
            >
              Continue <FaArrowRight />
            </Button>
          </Card.Footer>
        </Card>

      </Form>

      <br /><br /><br /><br />


    </Container>
  </div>
  );
}
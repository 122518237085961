import React, { useEffect } from 'react';
import { Form, Container, Card } from 'react-bootstrap';


export const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const submitForm = async () => { };

    return (<div>
        <Container>
            <br />


            <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>

                <Card className="text-left">
                    <Card.Header>Terms of Service</Card.Header>
                    <Card.Body>

                        <h3>Terms of Service</h3>

                        <br /><br />


                        <p>By submitting the ABN Registration form, I declare that Thornton & Associates Pty Ltd (ABN 28 631 107 714) is authorised to submit an ABN application to the Australian Tax Office on my behalf and to do so as my registered tax agent, and if necessary, submit a Business Name application to the Australian Securities & Investments Commission (ASIC). I understand that Thornton & Associates Pty Ltd will utilise information supplied by me in this form to apply for an ABN, and if necessary, will remain my ASIC agent and notify me when my business name requires renewal. I confirm that I have received the consent of all individuals referred to in this application form, to the submission of their personal information contained in this form to Thornton & Associates Pty Ltd and to the ATO and ASIC as necessary to process this application. I verify that the information supplied is, to the best of my knowledge, accurate and complete. I also confirm:</p>

                        <ul>
                            <li>I am not disqualified from managing corporations under Section 206B(1) of the Corporations Act 2001.</li>
                            <li>Within the last 5 years I have not been convicted of, or released from prison after being convicted of, and serving a term of imprisonment for, any of the criminal offences referred to in Section 32(1)(c) or (d) of the Business Names Registration Act 2011.</li>
                            <li>This application is submitted under, and is compliant with, the terms and conditions of the ASIC Electronic Lodgement Protocol.</li>
                            <li>The information supplied is, to the best of my knowledge, accurate and complete.</li>
                            <li>I have the TFN, date of birth and name, OR name, address and date of birth for all key people in my business.</li>
                            <li>I can provide evidence that I am entitled to an ABN if requested.</li>
                            <li>To provide true and correct information in my ABN application. Penalties of up to $12,600 can apply for each false or misleading statement made in an ABN application.</li>
                            <li>Once I have an ABN I must keep it up to date (within 28 days of any change) and cancel it once I am no longer trading or carrying on an enterprise.</li>
                        </ul>

                    </Card.Body>
                    <Card.Footer className="text-right">

                    </Card.Footer>
                </Card>

            </Form>

            <br /><br /><br /><br />


        </Container>
    </div>
    );
}
import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Container, Card } from 'react-bootstrap';
import {
  FaRegCheckCircle,
} from "react-icons/fa";

import '../App.css';


const CompanyNameIconPositive = styled(FaRegCheckCircle)`
  font-size: 8rem;
  color: #28a745;
`;

const ThankYou = () => {
  const paymentIntent = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );

  useEffect(async () => {
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payment_intent: paymentIntent,
      }),
    };
    const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/report-conversion-value`, options);
    const resultJSON = await result.json();

    window.gtag('event', 'conversion', {
      'send_to': 'AW-312837668/UbbFCJ7hpqEDEKSMlpUB',
      'value': resultJSON.amount,
      'currency': 'AUD',
      'transaction_id': paymentIntent,
    });
  });

  return (
    <div className="Enquiry-container">
      <Container>
        <Card>
          <Card.Header>Thank you!</Card.Header>
          <Card.Body>
            <CompanyNameIconPositive />
            <br />
            <br />
            <h1>Thank you!</h1>
            <Card.Text className="text-left">
              <br />
              <br />
              <h4 className="text-center">We have processed your transaction and will email you with confirmation details shortly</h4>
              <br />
              <br />
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default ThankYou;

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';

import '../App.css';


const postEnquiry = async (
    props,
    firstName,
    setFirstNameError,
    lastName,
    setLastNameError,
    email,
    setEmailError,
    phone,
    setPhoneError,
    message,
    businessName,
  ) => {

  if (firstName === '') {
    setFirstNameError('First name is required');
  } else {
    setFirstNameError('');
  }

  if (lastName === '') {
    setLastNameError('Last name is required');
  } else {
    setLastNameError('');
  }

  if (email === '') {
    setEmailError('Email is required');
  } else {
    setEmailError('');
  }

  if (phone === '') {
    setPhoneError('Phone is required');
  } else {
    setPhoneError('');
  }

  if (firstName === '' || lastName === '' || email === '' || phone === '') {
    return;
  }

  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      message,
      business_name: businessName,
    }),
  };
  await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/enquiry`, options);
  props.history.push('/thank-you');

}


const Enquiry = (props) => {

  const businessName = localStorage.getItem('proposedName');

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [message, setMessage] = useState('');

  return (!businessName
    ? <Redirect to={'/'} />
    : <div className="Enquiry-container">
      <Container>
        <Card>
          <Card.Header>Your name is available!</Card.Header>
          <Card.Body>
            <h1>We will register <span className="text-success">{businessName}</span> for you</h1>
            <Card.Text className="text-left">
              <br />
              <br />
              Please provide your contact details and a dedicated company consultant will
              reach out to finalise your company registration and help you start your new business!
              <br />
              <br />
            </Card.Text>

            <Form>
            <Row className="text-left">

              <Col>
                <Form.Group controlId="firstName">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    onChange={e => setFirstName(e.target.value)}
                    isInvalid={!!firstNameError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {firstNameError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="lastName">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    onChange={e => setLastName(e.target.value)}
                    isInvalid={!!lastNameError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lastNameError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>


            </Row>
            <Row className="text-left">

            <Col>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={e => setEmail(e.target.value)}
                  isInvalid={!!emailError}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            </Row>
            <Row className="text-left">

            <Col>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  onChange={e => setPhone(e.target.value)}
                  isInvalid={!!phoneError}
                />
                <Form.Control.Feedback type="invalid">
                  {phoneError}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>


            </Row>
            <Row className="text-left">
            <Col>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Tell us a little bit about your new business</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={e => setMessage(e.target.value)}
                />
              </Form.Group>
            </Col>

            </Row>
            </Form>

            <br />
            <br />



          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              variant={"success"}
              disabled={false}
              onClick={e => postEnquiry(
                props,
                firstName,
                setFirstNameError,
                lastName,
                setLastNameError,
                email,
                setEmailError,
                phone,
                setPhoneError,
                message,
                businessName,
              )}
            >
              Submit
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
}

export default Enquiry;

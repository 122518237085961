import React from 'react';

const Footer = () => {
  return (
    <div className="text-muted">
        <br /><br /><br />
        <hr />
        StartCompanies.com.au is owned and operated by Thornton &amp; Associates Pty Ltd - Paperless Digital Accountants.<br />
        The StartCompanies.com.au service is a hybrid tax advisory and business registration app provided to help micro-businesses get started trading in Australia and meet their ongoing tax compliance obligations.<br />
        StartCompanies.com.au operated by Thornton &amp; Associates Pty Ltd provides business advisory, tax agent and ASIC agent services.<br />
        Thornton &amp; Associates Pty Ltd is a registered Tax Agent (Registration Number 26010937) and a registered ASIC Agent (Number 44654).<br />
        <br />
        <a href="/terms">View Terms</a> - <a href="https://www.thorntonassociates.com.au">thorntonassociates.com.au</a> - Business or Tax Enquiries? Call Thornton & Associates paperless accountants on 1300 201 329
        <br /><br /><br />
    </div>
  );
}

export default Footer;

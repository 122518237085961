import React, { useEffect } from 'react';
import { Form, Container, Card } from 'react-bootstrap';


export default () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const submitForm = async () => { };

    return (<div>
        <Container>
            <br />


            <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>

                <Card className="text-left">
                    <Card.Header>Roadmap - Business and Tax Accounting Services</Card.Header>
                    <Card.Body>

                        <h3>Roadmap - Coming Soon</h3>

                        <br /><br />


                        <p>StartCompanies.com.au is operated in partnership with Thornton & Associates expert paperless accountants.</p>

                        <ul>
                            <li>ABN Registration and Business Starter Tool <span role="img" aria-label="tick">✅</span></li>
                            <li>Business Name Regsitration Tool <span role="img" aria-label="tick">✅</span></li>
                            <li>Company Registration - coming soon!</li>
                            <li>GST Registration and automatic BAS lodgement - coming soon!</li>
                            <li>Automatic Business Tax Returns - coming soon!</li>
                            <li>Business Performance Dashboard - coming soon!</li>
                        </ul>

                    </Card.Body>
                    <Card.Footer className="text-right">

                    </Card.Footer>
                </Card>

            </Form>

            <br /><br /><br /><br />


        </Container>
    </div>
    );
}
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Form, FormControl, Button, Spinner, Container, Row, Col, Card } from 'react-bootstrap';
import {
  FaRegCheckCircle,
  FaRegTimesCircle,
  FaArrowRight,
} from "react-icons/fa";

import '../App.css';

const CompanyNameIconPositive = styled(FaRegCheckCircle)`
  font-size: 8rem;
  color: #28a745;
`;

const CompanyNameIconNegative = styled(FaRegTimesCircle)`
  font-size: 8rem;
  color: #dc3545;
`;

const CompanyNameIconLoading = styled(Spinner)`
  height: 8rem;
  width: 8rem;
  border: 1em solid currentColor;
  border-right-color: transparent;
`;

async function companySearchAction(e, props, proposedName, setLoading, dispatch) {
  e.preventDefault();
  document.getSelection().removeAllRanges(); // Needed for Chrome

  if (proposedName !== '') {
    setLoading(true);
    const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/name-search?name=${proposedName}`);
    const proposedNameQueryResult = await result.json();

    console.log(proposedNameQueryResult);


    dispatch({ type: 'COMPANY_NAME_SEARCH', proposedName});
    dispatch({ type: 'COMPANY_NAME_SEARCH_RESULT', proposedNameQueryResult });

    props.history.push(`/search#${proposedName}`);

    setLoading(false);
  } else {
    alert('Name cannot be empty');
  }
}

async function companySearchFromQueryHash(proposedName, setLoading, dispatch) {
  dispatch({ type: 'COMPANY_NAME_SEARCH', proposedName});
  const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/name-search?name=${proposedName}`);
  const proposedNameQueryResult = await result.json();
  dispatch({ type: 'COMPANY_NAME_SEARCH_RESULT', proposedNameQueryResult });
}

const proceed = (e, props, proposedName) => {
  localStorage.setItem('proposedName', proposedName);

  //props.history.push('/enquiry');
  //props.history.push('/lodge/form-201/registered-office');
  props.history.push('/structure-selection');
}

function Search(props) {
  const nameSearch = useSelector(state => state.nameSearch);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [proposedName, setProposedName] = useState('');

  const queryHashProposedName = decodeURIComponent(props.location.hash.substr(1));

  if(!nameSearch) {
    companySearchFromQueryHash(queryHashProposedName, setLoading, dispatch);
  }

  return (!nameSearch && queryHashProposedName === ''
    ? <Redirect to={'/'} />
    : <div className="Registration-container">
      <Container>
        <Card className="text-center">
          <Card.Header>Australian Company Name Search Result</Card.Header>
          <Card.Body>
            <h1>{nameSearch && nameSearch.proposedName ? nameSearch.proposedName : queryHashProposedName}</h1>
            <Card.Text>
              {nameSearch && nameSearch.proposedNameQueryResult && nameSearch.proposedNameQueryResult.result && nameSearch.proposedNameQueryResult.result
                ? nameSearch.proposedNameQueryResult.result.code !== 'Unavailable'
                  ? <CompanyNameIconPositive />
                  : <CompanyNameIconNegative />
                : <CompanyNameIconLoading animation="border" variant="secondary" size="lg" />
              }
            </Card.Text>
            <Card.Title>
              {nameSearch && nameSearch.proposedNameQueryResult && nameSearch.proposedNameQueryResult.result
                ? nameSearch.proposedNameQueryResult.result.code !== 'Unavailable' ? `This name is AVAILABLE` : `This name is UNAVAILABLE`
                : `Company name status loading`
              }
            </Card.Title>
            <Card.Text>
              {nameSearch && nameSearch.proposedNameQueryResult && nameSearch.proposedNameQueryResult.result
                ? nameSearch.proposedNameQueryResult.result.code !== 'Unavailable'
                  ? `You can register a company with this name by clicking "Proceed to registration" below!`
                  : `You must select a different name by searching again below before you can proceed`
                : `We're checking on your name, please wait while we query the companies database`
              }
            </Card.Text>
            <hr />
            <Card.Title className="text-left">Not the name you want?</Card.Title>
            <Card.Text className="text-left">You can search for a new name again</Card.Text>
            <Form onSubmit={(e) => { companySearchAction(e, props, proposedName, setLoading, dispatch) } }>
            <Row>
              <Form.Group as={Col} controlId="changeProposedName">
                <FormControl
                  type="text"
                  placeholder="Check another name"
                  value={proposedName}
                  onChange={(e) => setProposedName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="text-left">
                <Button
                  type="submit"
                  variant="info"
                >
                  {loading
                    ? <span><Spinner animation="border" variant="light" size="sm" /> Searching</span>
                    : <span>Search again</span>
                  }
                </Button>
              </Form.Group>
            </Row>
            </Form>
          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              variant={nameSearch && nameSearch.proposedNameQueryResult && nameSearch.proposedNameQueryResult.result
                ? nameSearch.proposedNameQueryResult.result.code !== 'Unavailable' ? "success" : "success disabled"
                : "success disabled"
              }
              disabled={nameSearch && nameSearch.proposedNameQueryResult && nameSearch.proposedNameQueryResult.result
                ? nameSearch.proposedNameQueryResult.result.code !== 'Unavailable' ? false : true
                : true
              }
              onClick={e => proceed(
                e,
                props,
                nameSearch && nameSearch.proposedName ? nameSearch.proposedName : queryHashProposedName,
              )}
            >
              Proceed to registration <FaArrowRight />
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
}

export default Search;

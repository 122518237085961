import React from 'react';
import { Form, Button, Container, Card, Row, Col } from 'react-bootstrap';
import { FaArrowRight, FaUser, FaBuilding } from "react-icons/fa";

import FormSteps from '../FormSteps';


export const StructureSelection = (props) => {
  const proposedName = localStorage.getItem('proposedName');


  const submitForm = () => {
    props.history.push('/lodge/abn-registration');
  };

  return (
    <div>
      <Container>
        <br />
        <FormSteps companyName={proposedName} step={1} />

        <br />

        <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>

          <Row className="text-left">
            <Col>
              <Card className="text-left">
                <Card.Header>Select ABN</Card.Header>
                <Card.Body>

                  <div className="text-center">
                    <FaUser size="5em" />
                    <br />
                    <br />
                    <h2>
                      Register ABN - $15
                    </h2>
                    <h3 className="text-muted">
                      &nbsp;
                    </h3>

                  </div>

                  <br /><br />
                  <ul>
                    <li>Suitable for Sole Traders</li>
                    <li>Ideal to start up a new business</li>
                    <li>Begin sending invoices immediatetly</li>
                    <li>Lowest cost</li>
                    <li>Easier to manage</li>
                  </ul>

                  <br />


                </Card.Body>
                <Card.Footer className="text-right">
                  <Button
                    type="submit"
                    variant="success"
                  >
                    Select ABN <FaArrowRight />
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col>
              <Card className="text-left">
                <Card.Header>Select Company (Coming Soon)</Card.Header>
                <Card.Body>

                  <div className="text-center">
                    <FaBuilding size="5em" className="text-muted" />
                    <br />
                    <br />
                    <h2 className="text-muted">
                      Register Company - $795
                    </h2>
                    <h3 className="text-muted">
                      Coming Soon
                    </h3>

                  </div>

                  <br /><br />
                  <ul>
                    <li>Suitable for multiple business partners</li>
                    <li>Limited liability</li>
                    <li>Usually required for larger or established businesses</li>
                    <li>Higher cost</li>
                    <li>More complex to manage</li>
                  </ul>

                  <br />


                </Card.Body>
                <Card.Footer className="text-right">
                  <Button
                    type="submit"
                    variant="success"
                    disabled={true}
                  >
                    Select Company <FaArrowRight />
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>

        <br /><br /><br /><br />

        <div className="Form201-footer"></div>
      </Container>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spinner, Button, Container, Card, Row, Col, Table } from 'react-bootstrap';
import { FaArrowRight } from "react-icons/fa";

import FormSteps from '../FormSteps';


export const ABNRegistrationLodge = (props) => {
  const history = useHistory();
  const proposedName = localStorage.getItem('proposedName');
  const applicationId = localStorage.getItem('abnApplicationId');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const submitForm = async () => {
    setLoading(true);
    const options = {
      method: 'post',
    }
    const result = await fetch(`${process.env.REACT_APP_STARTCOMPANIES_API_URL}/abn-application/${applicationId}/lodge`, options);
    const resultJSON = await result.json();
    if (resultJSON.abn) {
      localStorage.setItem('abn', resultJSON.abn);
    }
    setLoading(false);

    console.log(result);
    if (result.ok) {
      history.push('/thank-you');
    }  

  };

  return (<div>
    <Container>
      <br />
      <FormSteps companyName={proposedName} step={4} />

      <br />


      <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>

        <Card className="text-left">
          <Card.Header>Step 4. Pay &amp; Lodge</Card.Header>
          <Card.Body>

            <h3>Summary</h3>

            <br /><br />

            <Row className="justify-content-md-center">
              <Col sm={8}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Unit Price</th>
                      <th>Amount</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ABN Registration</td>
                      <td className="text-right">$14.99</td>
                      <td>1</td>
                      <td className="text-right">$14.99</td>
                    </tr>
                    <tr>
                      <td>ABN Registration (Promotional Discount)</td>
                      <td className="text-right">-$14.99</td>
                      <td>1</td>
                      <td className="text-right">-$14.99</td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                      <td className="text-right"><strong>Total</strong></td>
                      <td className="text-right"><strong>$0.00</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <h4 className="text-center">No Payment Required</h4>
            <h4 className="text-center">Your ABN will be emailed to you after lodgement</h4>
            <br /><br /><br />

          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              type="submit"
              variant="success"
              disabled={loading}
            >
              {loading
                ? <Spinner animation="border" variant="light" size="sm" />
                : null
              } Lodge <FaArrowRight />
            </Button>
          </Card.Footer>
        </Card>

      </Form>

      <br /><br /><br /><br />


    </Container>
  </div>
  );
}
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, } from 'react-bootstrap';

function Navigation() {
  const history = useHistory();
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">StartCompanies.com.au</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/coming-soon">About</Nav.Link>
          <Nav.Link href="/coming-soon">Pricing</Nav.Link>
          <NavDropdown title="Products" id="basic-nav-dropdown">
            <NavDropdown.Item href="/lodge/abn-registration">ABN Registration</NavDropdown.Item>
            <NavDropdown.Item href="/coming-soon">Company Registration</NavDropdown.Item>
            <NavDropdown.Item href="/coming-soon">GST Registration</NavDropdown.Item>
            <NavDropdown.Item href="/coming-soon">Business Activity Statement (BAS) Lodgement</NavDropdown.Item>
            <NavDropdown.Item href="/coming-soon">Business Tax Returns</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav className="justify-content-end">
          <Nav.Link href="/coming-soon">Register Company</Nav.Link>
          <Button variant="outline-dark" onClick={() => history.push('/coming-soon')}>Manage Company</Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );

}

export default Navigation;

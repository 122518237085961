import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import Navigation from './components/Navigation';
import Footer from './components/Footer';

import Home from './components/Home';
import Search from './components/Search';
import Enquiry from './components/Enquiry';
import ThankYou from './components/ThankYou';

import ComingSoon from './components/ComingSoon';

import { StructureSelection } from './components/steps/StructureSelection';
import { ABNRegistrationDetails } from './components/steps/ABNRegistrationDetails';
import { ABNRegistrationReview } from './components/steps/ABNRegistrationReview';
import { ABNRegistrationLodge } from './components/steps/ABNRegistrationLodge';

import { Lodge } from './components/Lodge';

import { Terms } from './components/steps/Terms';


class Routes extends Component {
  render() {
    return (
      <Router>
        <Navigation />
        <Switch>
          <Route path="/thank-you" component={ThankYou} />

          <Route path="/enquiry" component={Enquiry} />
          <Route path="/search" component={Search} />
          <Route path="/structure-selection" component={StructureSelection} />
          <Route path="/lodge/abn-registration/review" component={ABNRegistrationReview} />
          <Route path="/lodge/abn-registration/lodge" component={ABNRegistrationLodge} />
          <Route path="/lodge/abn-registration" component={ABNRegistrationDetails} />

          <Route path="/lodge" component={Lodge} />

          <Route path="/coming-soon" component={ComingSoon} />

          <Route path="/terms" component={Terms} />
          <Route path="/" component={Home} />
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default Routes;
